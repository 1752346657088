import React, {useEffect} from 'react'
import {graphql} from 'gatsby'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import SEO from '../components/SEO'
import HeroWithoutSlider from '../components/HeroWithoutSlider'
import BlogBlockSection from '../sections/BlogBlockSection'
import Header from '../components/Header'
import Layout from "../components/Layout";

const ArticleTemplate = ({
  pageContext: {
    breadcrumb: {crumbs},
  },
  data,
  location,
}) => {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()

  useEffect(() => {
    if (typeof window !== 'undefined' && data.strapiCategoryAuthorPage.en_disabled) {
      changeLanguage('de')
    }
  }, [])

  return (
    <Layout dataLocales={data} location={location}>
      <Header dataLang={data.strapiCategoryAuthorPage.en_disabled} />
      <SEO
        title={data.strapiCategoryAuthorPage.seo_page_title[language]}
        description={data.strapiCategoryAuthorPage.seo_page_description[language]}
      />
      <HeroWithoutSlider
        title={t('sections.blogs.title')}
        crumbs={crumbs}
        subtitle={t('sections.blogs.subtitle')}
        category
      />
      <BlogBlockSection
        data={data.allStrapiBlog.edges}
        title={t('sections.blogs.titleSecond')}
        subtitle={t('sections.blogs.subtitleSecond')}
        notSliced
        noAllBlogs
        showCategories
        minHeight
        categoryData={data}
        negativeMargin
        location={location}
      />
    </Layout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query CategoryAuthorTemplate($page_url: String!, $language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    strapiCategoryAuthorPage {
      en_disabled
      seo_page_title {
        de
        en
      }
      seo_page_description {
        de
        en
      }
    }
    allStrapiBlog(
      filter: {lawyers: {elemMatch: {page_url: {eq: $page_url}}}}
      sort: {order: DESC, fields: date_for_sort}
    ) {
      edges {
        node {
          lawyers {
            page_url
          }
          services {
            page_url
          }
          article {
            de
            en
          }
          date {
            de
            en
          }
          description_short {
            de
            en
          }
          navigation {
            de
            en
          }
          reading_time {
            de
            en
          }
          page_url
          date_for_sort
          published_at
          title {
            de
            en
          }
        }
      }
    }
    allStrapiLawyer {
      edges {
        node {
          name
          page_url
          position {
            de
            en
          }
          services {
            name {
              de
              en
            }
          }
        }
      }
    }
    allStrapiService {
      edges {
        node {
          name {
            de
            en
          }
          service_types {
            de
            en
          }
          page_url
        }
      }
    }
  }
`
